import * as deMessagesCommon from './de/i18nCommon.json';
import * as enMessagesCommon from './en/i18nCommon.json';
import * as deMessagesCategories from './de/i18nCategories.json';
import * as enMessagesCategories from './en/i18nCategories.json';
import * as deMessagesMainPage from './de/i18nMainPage.json';
import * as enMessagesMainPage from './en/i18nMainPage.json';
import * as deMessagesProlog from './de/i18nPrologTexts.json';
import * as enMessagesProlog from './en/i18nPrologTexts.json';
import * as deMessagesCookieBar from './de/i18nCookieBar.json';
import * as enMessagesCookieBar from './en/i18nCookieBar.json';
import * as deMessagesContactPage from './de/i18nContactPage.json';
import * as enMessagesContactPage from './en/i18nContactPage.json';
import * as deMessagesFooterModal from './de/i18nFooterModal.json';
import * as enMessagesFooterModal from './en/i18nFooterModal.json';
import * as deMessagesFooter from './de/i18nFooter.json';
import * as enMessagesFooter from './en/i18nFooter.json';
import * as deMessagesSearch from './de/i18nSearch.json';
import * as enMessagesSearch from './en/i18nSearch.json';
import * as deMessagesSupport from './de/i18nSupport.json';
import * as enMessagesSupport from './en/i18nSupport.json';
import * as deMessagesNotFound from './de/i18nNotFound.json';
import * as enMessagesNotFound from './en/i18nNotFound.json';
import * as deMessagesQrOverview from './de/i18nQrOverview.json';
import * as enMessagesQrOverview from './en/i18nQrOverview.json';
import * as deMessagesUpload from './de/i18nUpload.json';
import * as enMessagesUpload from './en/i18nUpload.json';

/**
 * A single object containing all translation texts.
 * This is required by the TranslationProvider to pass
 * these texts to the 'intl' library.
 */
export const Translations = {
    de: {
        ...deMessagesCommon,
        ...deMessagesCategories,
        ...deMessagesMainPage,
        ...deMessagesProlog,
        ...deMessagesCookieBar,
        ...deMessagesContactPage,
        ...deMessagesFooterModal,
        ...deMessagesFooter,
        ...deMessagesSearch,
        ...deMessagesSupport,
        ...deMessagesNotFound,
        ...deMessagesQrOverview,
        ...deMessagesUpload,
    },
    en: {
        ...enMessagesCommon,
        ...enMessagesCategories,
        ...enMessagesMainPage,
        ...enMessagesProlog,
        ...enMessagesCookieBar,
        ...enMessagesContactPage,
        ...enMessagesFooterModal,
        ...enMessagesFooter,
        ...enMessagesSearch,
        ...enMessagesSupport,
        ...enMessagesNotFound,
        ...enMessagesQrOverview,
        ...enMessagesUpload,
    },
};
