import React, { Suspense } from 'react';
import { SheetOverview } from '../components/category/sheetPage/SheetOverview';
import { MainSkeleton } from '../components/MainSkeleton';

function SheetOverviewRoute() {
    return (
        <Suspense fallback={<MainSkeleton />}>
            <SheetOverview />
        </Suspense>
    );
}

export default React.memo(SheetOverviewRoute);
