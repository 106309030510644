import { useEffect } from 'react';
import LegalNoticeTab from './LegalNoticeTab';
import ProviderTab from './ProviderTab';
import PrivacyStatementTab from './PrivacyStatementTab';
import SettingsTab from './SettingsTab';
import { WbModal, WbModalLevel, WbTab, WbTabContent, WbTabs } from '@workbench/react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../../common/i18n/useTranslation';

const useStyles = makeStyles()((theme: Theme) => ({
    modal: {
        zIndex: 9999, //needs higher zIndex than Cookiebar
    },
    tab: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

interface FooterModalProps {
    hideFooterModal: () => void;
    footerModalTabName: string;
}

export default function FooterModal(props: FooterModalProps) {
    const { classes } = useStyles();
    const translate = useTranslation();

    useEffect(() => {
        // Update the document title using the browser API
        const modal = document.querySelector('wb-modal');

        if (modal) {
            modal.addEventListener('wbmodalclose', function () {
                props.hideFooterModal();
            });
        }
    }, [props]);

    return (
        <WbModal className={classes.modal} variant="edit-mobile-fullscreen" closeOnBackdropClick closeOnEscapeKey>
            <WbModalLevel level={1} closeButtonLabel="Close">
                <WbTabs id="tabs" selected={props.footerModalTabName === '' ? 'settings' : props.footerModalTabName}>
                    <WbTab id="provider" name="provider" className={classes.tab}>
                        {translate('Provider')}
                    </WbTab>
                    <WbTab id="legalNotice" name="legalNotice" className={classes.tab}>
                        {translate('LegalNotice')}
                    </WbTab>
                    <WbTab id="settings" name="settings" className={classes.tab}>
                        {translate('Settings')}
                    </WbTab>
                    <WbTab id="dataPrivacy" name="dataPrivacy" className={classes.tab}>
                        {translate('PrivacyStatement')}
                    </WbTab>
                    <WbTabContent id="providerTabContent" name="provider" slot="content">
                        <ProviderTab />
                    </WbTabContent>
                    <WbTabContent id="legalNoticeTabContent" name="legalNotice" slot="content">
                        <LegalNoticeTab />
                    </WbTabContent>
                    <WbTabContent id="settingsTabContent" name="settings" slot="content">
                        <SettingsTab />
                    </WbTabContent>
                    <WbTabContent id="dataPrivacyTabContent" name="dataPrivacy" slot="content">
                        <PrivacyStatementTab />
                    </WbTabContent>
                </WbTabs>
            </WbModalLevel>
        </WbModal>
    );
}
