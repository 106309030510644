import { theme } from './AppTheme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CategoryOverview } from './category/CategoryOverview';
import { CATEGORY_PROPS } from '../common/Constants';
import { TranslationProvider } from '../common/i18n/TranslationProvider';
import { Frame } from './Frame';
import routes from '../routes/routeConfig';
import ErrorBoundary from './error/error';
import { CategoryMenuProvider } from './category/context/CategoryContext';
import { ScrollLockProvider } from '../utility/ScrollLockContext';
import { ThemeProvider } from '@emotion/react';
import { LoadingProvider } from './category/context/LoadingContext';

export function App() {
    return (
        <ThemeProvider theme={theme}>
            <TranslationProvider>
                <ScrollLockProvider>
                    <CategoryMenuProvider>
                        <ErrorBoundary>
                            <BrowserRouter>
                                <LoadingProvider>
                                    <Frame>
                                        <Routes>
                                            {routes.map((route) => (
                                                <Route
                                                    key={route.path}
                                                    path={route.path}
                                                    element={route.getComponent()}
                                                />
                                            ))}
                                            {CATEGORY_PROPS.map((element) => {
                                                return (
                                                    <Route
                                                        path={`/category${element.link}`}
                                                        element={
                                                            <CategoryOverview
                                                                description={element.description}
                                                                image={element.image}
                                                            />
                                                        }
                                                        key={element.description}
                                                    />
                                                );
                                            })}
                                        </Routes>
                                    </Frame>
                                </LoadingProvider>
                            </BrowserRouter>
                        </ErrorBoundary>
                    </CategoryMenuProvider>
                </ScrollLockProvider>
            </TranslationProvider>
        </ThemeProvider>
    );
}
