import { Theme } from '@mui/material';
import { WbGrid, WbGridCol, WbGridRow, WbHeading, WbLink, WbText } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles()((theme: Theme) => ({
    gridContainer: {
        display: 'grid',
        gap: theme.spacing(3),
        padding: theme.spacing(1),
    },
}));

export default function LegalNoticeTab() {
    const { classes } = useStyles();
    const translate = useTranslation();

    return (
        <WbGrid className={classes.gridContainer} fullWidth>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="s">{translate('LegalNoticeTabTitle')}</WbHeading>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('LegalNoticeTabCopyRightsTitle')}</WbHeading>
                    <WbText size="l">
                        <FormattedMessage
                            id="LegalNoticeTabCopyRightsContent"
                            defaultMessage={'LegalNoticeTabCopyRightsContent'}
                            values={{ br: <br />, year: new Date().getFullYear() }}
                        />
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('LegalNoticeTabTrademarksTitle')}</WbHeading>
                    <WbText size="l">
                        <FormattedMessage
                            id="LegalNoticeTabTrademarksContent"
                            defaultMessage={'LegalNoticeTabTrademarksContent'}
                            values={{ br: <br /> }}
                        />
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('LegalNoticeTabLicensesTitle')}</WbHeading>
                    <WbText size="l">
                        <FormattedMessage
                            id="LegalNoticeTabLicensesContent"
                            defaultMessage={'LegalNoticeTabLicensesContent'}
                            values={{ br: <br /> }}
                        />
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('LegalNoticeTabVsbgTitle')}</WbHeading>
                    <WbText size="l">
                        <FormattedMessage
                            id="LegalNoticeTabVsbgContent"
                            defaultMessage={'LegalNoticeTabVsbgContent'}
                            values={{ br: <br /> }}
                        />
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('LegalNoticeTabOnlineDisputeTitle')}</WbHeading>
                    <WbText size="l">
                        <FormattedMessage
                            id="LegalNoticeTabOnlineDisputeContent"
                            defaultMessage={'LegalNoticeTabOnlineDisputeContent'}
                            values={{ br: <br /> }}
                        />
                        <WbLink
                            variant="inline"
                            href="https://ec.europa.eu/consumers/odr"
                            target="_blank"
                            rel="noreferrer"
                            className="wb-link wb-link--inline"
                        >
                            https://ec.europa.eu/consumers/odr
                        </WbLink>
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('LegalNoticeTabLiabilityTitle')}</WbHeading>
                    <WbText size="l">
                        <FormattedMessage
                            id="LegalNoticeTabLiabilityContent"
                            defaultMessage={'LegalNoticeTabLiabilityContent'}
                            values={{ br: <br /> }}
                        />
                    </WbText>
                </WbGridCol>
            </WbGridRow>
        </WbGrid>
    );
}
