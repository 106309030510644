import { Routes } from './routes';
import MainRoute from './MainRoute';
import ContactRoute from './ContactRoute';
import OilFinderRoute from './OilFinderRoute';
import QrOverviewRoute from './QrOverviewRoute';
import SheetOverviewRoute from './SheetOverviewRoute';
import NotFoundRoute from './NotFoundRoute';

export interface RouteConfigEx {
    /**
     * @type {string}
     */
    name: Routes;
    /**
     * @type {string}
     *
     * e.g. '/test3/:paramId', with optional parameter '/test3/:paramId?'
     */
    path: string;
    /**
     * @type {React.ComponentType<any>}
     */
    getComponent: () => React.ReactElement;

    /**
     * @type {string}
     */
    displayName?: string;
    /**
     * Subroutes
     *
     * @type {RouteConfigEx[]}
     */
    routes?: RouteConfigEx[];
}

const routes: RouteConfigEx[] = [
    {
        getComponent: () => <MainRoute />,
        name: Routes.home,
        path: '/',
    },
    {
        getComponent: () => <NotFoundRoute />,
        name: Routes.not_found,
        path: '*',
    },
    {
        getComponent: () => <ContactRoute />,
        name: Routes.contact,
        path: '/contact',
    },
    {
        getComponent: () => <OilFinderRoute />,
        name: Routes.oil_finder,
        path: '/oil-finder',
    },
    {
        getComponent: () => <QrOverviewRoute />,
        name: Routes.qr_overview,
        path: '/qrcode/:productId',
    },
    {
        getComponent: () => <SheetOverviewRoute />,
        name: Routes.sheet_overview,
        path: '/sheet/:sheetNumber',
    },
    {
        // Legacy: some users still use old sheet links like so "/sheet/224.1/de"
        getComponent: () => <SheetOverviewRoute />,
        name: Routes.sheet_overview,
        path: '/sheet/:sheetNumber/:language',
    },
];

export default routes;
