import { WbAspectRatio, WbGrid, WbGridCol, WbGridRow, WbHeading, WbText } from '@workbench/react';
import engine from '../../../../assets/image/engineIntroduction.png';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles()((theme: Theme) => ({
    preLine: {
        whiteSpace: 'pre-line',
        marginTop: theme.spacing(2),
    },
}));

export default function Introduction() {
    const { classes } = useStyles();

    return (
        <WbGrid>
            <WbGridRow>
                <WbGridCol mq1={12} mq2={12} mq3={6} mq4={6} mq5={6} mq6={6}>
                    <WbAspectRatio ratio="3x2">
                        <img src={engine} alt="alt text" />
                    </WbAspectRatio>
                </WbGridCol>
                <WbGridCol mq1={12} mq2={12} mq3={6} mq4={6} mq5={6} mq6={6}>
                    <WbHeading tag="h2" size="m">
                        Mercedes-Benz Operating Fluids
                    </WbHeading>
                    <WbText tag="p" size="s" className={classes.preLine}>
                        <FormattedMessage
                            id="IntroductionContent"
                            defaultMessage={'IntroductionContent'}
                            values={{ br: <br /> }}
                        />
                    </WbText>
                </WbGridCol>
            </WbGridRow>
        </WbGrid>
    );
}
