import React, { Suspense } from 'react';
import { NotFound } from '../components/notFound/NotFound';
import { MainSkeleton } from '../components/MainSkeleton';

function NotFoundRoute() {
    return (
        <Suspense fallback={<MainSkeleton />}>
            <NotFound />
        </Suspense>
    );
}

export default React.memo(NotFoundRoute);
