import React, { Suspense } from 'react';
import Contact from '../components/general/contact/Contact';
import { MainSkeleton } from '../components/MainSkeleton';

function ContactRoute() {
    return (
        <Suspense fallback={<MainSkeleton />}>
            <Contact />
        </Suspense>
    );
}

export default React.memo(ContactRoute);
