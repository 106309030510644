import { Theme } from '@mui/material';
import { WbGrid, WbGridCol, WbGridRow, WbHeading, WbLink, WbText } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
    },
    mercedesContact: {
        whiteSpace: 'pre-line',
    },
    chairmanContainer: {
        display: 'flex',
        gap: theme.spacing(0.5),
    },
}));

export default function ProviderTab() {
    const { classes } = useStyles();
    const translate = useTranslation();

    return (
        <WbGrid className={classes.container} fullWidth>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('ProviderTabTitle')}</WbHeading>
                    <WbText size="l" className={classes.mercedesContact}>
                        <FormattedMessage
                            id="ProviderTabMercedesAddress"
                            defaultMessage={'ProviderTabMercedesAddress'}
                            values={{ br: <br /> }}
                        />
                        <WbLink variant="inline" href="mailto:dialog.mb@mercedes-benz.com">
                            dialog.mb@mercedes-benz.com
                        </WbLink>
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('ProviderTabRepresentedTitle')}</WbHeading>
                    <WbText size="l">{translate('ProviderTabRepresentedContent')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <div className={classes.chairmanContainer}>
                        <WbText size="l" strong>
                            {translate('ProviderTabChairmanTitle')}
                        </WbText>
                        <WbText size="l">{translate('ProviderTabChairmanContent')}</WbText>
                    </div>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbText size="l">{translate('ProviderTabCourtOfRegistry')}</WbText>
                    <WbText size="l">{translate('ProviderTabVatId')}</WbText>
                </WbGridCol>
            </WbGridRow>
        </WbGrid>
    );
}
