import { WbCard, WbIcon, WbLink, WbText, WbTooltip } from '@workbench/react';
import { TooltipPosition } from '@workbench/core/dist/types/components/overlays/tooltip/tooltip-position';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../../../common/i18n/useTranslation';
import I18nTexts from '../../../../common/i18n/texts/I18nTexts';
import { useTranslationContext } from '../../../../common/i18n/TranslationProvider';
import { useEffect, useState } from 'react';

const useStyles = makeStyles()((theme: Theme) => ({
    card: {
        color: theme.palette.info.main,
        borderRadius: theme.spacing(8),
        transition: 'all 0.2s ease',
        '&:hover': {
            transform: 'scaleX(1.25) scaleY(1.25)',
        },
    },
}));

interface IconNavigationProps {
    description: string;
    link: string;
    position: TooltipPosition;
}

export default function IconNavigation(props: IconNavigationProps) {
    const { classes } = useStyles();
    const translate = useTranslation();
    const { langState } = useTranslationContext();

    // to update the tooltip key, forcing a re-render of the tooltip.when the language is changed.
    const [tooltipKey, setTooltipKey] = useState(0);
    useEffect(() => {
        setTooltipKey((prevKey) => prevKey + 1);
    }, [langState]);

    return (
        //There is a open prop that should always show the tooltip.. doesnt work tho?
        //see https://pages.git.i.mercedes-benz.com/dh-io-workbench/documentation/v7/components/tooltip/api/
        <WbTooltip position={props.position} key={tooltipKey}>
            <WbLink href={props.link} variant="inline">
                <WbCard variant="light-grey" className={classes.card}>
                    <WbIcon name="hotspot-active" />
                </WbCard>
            </WbLink>
            <div slot="content">
                <WbText size="s">{translate(props.description as keyof I18nTexts)}</WbText>
            </div>
        </WbTooltip>
    );
}
