import { CategoryDescription } from '../common/Constants';

export const getCategoryAsGermanString = (category: string) => {
    switch (category) {
        case CategoryDescription.ENGINEOIL:
            return 'Motoröl';
        case CategoryDescription.BRAKEFLUID:
            return 'Bremsflüssigkeit';
        case CategoryDescription.FUEL:
            return 'Kraftstoff';
        case CategoryDescription.GEAROIL:
            return 'Getriebeöl';
        case CategoryDescription.GREASE:
            return 'Fett';
        case CategoryDescription.HYDRAULICFLUID:
            return 'Hydrauliköl';
        case CategoryDescription.PRESERVATIONAGENT:
            return 'Konservierungsmittel';
        case CategoryDescription.REFRIGERATOROIL:
            return 'Kompressoröl';
        case CategoryDescription.STEERINGGEAROIL:
            return 'Lenkgetriebeöl';
        case CategoryDescription.ADBLUE:
            return 'NOx-Reduktionsmittel';
        case CategoryDescription.COOLANT:
        case 'Korrosions- / Frostschutzmittel': //Hacky..
            return 'Frostschutz';
        case CategoryDescription.REFRIGERANT:
            return 'Kältemittel';
        case CategoryDescription.WINDSHIELD:
            return 'Scheibenwaschmittel-Konzentrat';
        default:
            return category;
    }
};

export const reduceWidthRemoveMarginInHtml = (html: string): string => {
    let re1 = /1\d\d%/g;
    let re2 = /width="1\d\d%"/g;
    let re3 = /width:1\d\d\.\d%/g;
    let re4 = /width:1\d\d\.\d\d%/g;
    let re5 = /margin-left:\d\.\dpt/g;
    let re6 = /margin-left:-\.\dpt/g;
    let re7 = /margin-right:\d\.\dpt/g;
    return html
        .replaceAll(re1, '100%')
        .replaceAll(re2, 'width="100%"')
        .replaceAll(re3, 'width:100.0%')
        .replaceAll(re4, 'width:100.00%')
        .replaceAll(re5, '')
        .replaceAll(re6, '')
        .replaceAll(re7, '');
};
