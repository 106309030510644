import { WbCard, WbCardLayoutNba, WbIcon, WbLink, WbText } from '@workbench/react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CategoryType } from '../../../../common/Constants';
import { useTranslation } from '../../../../common/i18n/useTranslation';

const useStyles = makeStyles()((theme: Theme) => ({
    icon: {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
}));

interface CategorySliderCardProps {
    category: CategoryType;
}

export default function CategorySliderCard(props: CategorySliderCardProps) {
    const { icon, description, link } = props.category;
    const { classes } = useStyles();
    const translate = useTranslation();

    return (
        <WbLink href={`/category${link}`} variant="inline">
            <WbCard interactive variant="dark-grey">
                <WbCardLayoutNba>
                    <WbIcon className={classes.icon} src={icon} />
                    <WbText size="l" strong>
                        {translate(description)}
                    </WbText>
                </WbCardLayoutNba>
            </WbCard>
        </WbLink>
    );
}
