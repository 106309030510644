import engineOilImage from '../../../../assets/image/engineOil.png';
import carPhoneImage from '../../../../assets/image/carPhone.png';
import Teaser from './Teaser';
import Introduction from '../introduction/Introduction';
import { WbGrid, WbGridCol, WbGridRow } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { useTranslation } from '../../../../common/i18n/useTranslation';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        display: 'grid',
        gap: theme.spacing(8),
    },
    teaserContainer: {
        display: 'grid',
        gap: theme.spacing(4),
    },
}));

type TeaserContentType = {
    title: string;
    text: string;
    url: string;
    image: string;
};

export default function TeaserContainer() {
    const { classes } = useStyles();
    const translate = useTranslation();

    const teaserContent: TeaserContentType[] = [
        {
            title: translate('MbOgOil'),
            text: translate('MbChoice'),
            url: translate('Link1'),
            image: engineOilImage,
        },
        {
            title: translate('OperatingInstructions'),
            text: translate('OperatingInstructionsContent'),
            url: translate('Link2'),
            image: carPhoneImage,
        },
    ];

    return (
        <div className={classes.container}>
            <Introduction />
            <WbGrid>
                <WbGridRow className={classes.teaserContainer}>
                    {teaserContent.map((element) => {
                        return (
                            <WbGridCol key={element.title} mq1={12} mq2={12} mq3={6} mq4={6} mq5={6} mq6={6}>
                                <Teaser
                                    title={element.title}
                                    text={element.text}
                                    url={element.url}
                                    image={element.image}
                                />
                            </WbGridCol>
                        );
                    })}
                </WbGridRow>
            </WbGrid>
        </div>
    );
}
