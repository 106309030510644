import { IProduct, emptyProductObject } from './IProduct';

export interface IProductSheet {
    sheetNumber: string;
    title: string;
    productCollection: IProduct[];
    category: string;
    titleDe: string;
    titleEn: string;
    additionalInfoDe: string;
    additionalInfoEn: string;
    publishDate: Date;
    pdfDe: string;
    pdfEn: string;
    htmlDe: string;
    htmlEn: string;
    privateLabels: Record<string, string>;
    pdfLinkDe: string;
    pdfLinkEn: string;
}

export const emptyProductSheetObject = {
    sheetNumber: '',
    title: '',
    productCollection: [emptyProductObject],
    category: '',
    titleDe: '',
    titleEn: '',
    additionalInfoDe: '',
    additionalInfoEn: '',
    publishDate: new Date(),
    pdfDe: '',
    pdfEn: '',
    htmlDe: '',
    htmlEn: '',
    privateLabels: {},
    pdfLinkDe: '',
    pdfLinkEn: '',
};
