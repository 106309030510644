import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { SupportedLanguages } from './SupportedLanguages';
import { useTranslationContext } from './TranslationProvider';


const useStyles = makeStyles()((theme: Theme) => ({
    languageContainer: {
        alignItems: 'center',
        borderRadius: theme.spacing(0.1),
        fontSize: theme.spacing(1.6),
        color: theme.palette.grey[200],
        display: 'flex',
        width: theme.spacing(3.5),
        cursor: 'pointer',
        fontFamily: 'var(--wb-font-text)',
    },
    unselectedLanguage: {
        paddingLeft: theme.spacing(0.7),
        paddingRight: theme.spacing(0.7),
        '&:hover': {
            fontWeight: 'bold',
        },
    },
    selectedLanguage: {
        backgroundColor: theme.palette.grey[900],
        paddingLeft: theme.spacing(0.7),
        paddingRight: theme.spacing(0.7),
        fontWeight: 'bold',
    },
}));

const LanguageSelector = () => {
    const { classes } = useStyles();
    const { langState, updateLangState } = useTranslationContext();
    return (
        <>
            <nav aria-label="Language Navigation">
                <a onClick={() => updateLangState(SupportedLanguages.de)}>
                    <div className={classes.languageContainer}>
                        <div
                            className={
                                langState === SupportedLanguages.de
                                    ? classes.selectedLanguage
                                    : classes.unselectedLanguage
                            }
                        >
                            <span>{SupportedLanguages.de.toUpperCase()}</span>
                        </div>
                    </div>
                </a>
            </nav>
            <nav aria-label="Language Navigation">
                <a onClick={() => updateLangState(SupportedLanguages.en)}>
                    <div className={classes.languageContainer}>
                        <div
                            className={
                                langState === SupportedLanguages.en
                                    ? classes.selectedLanguage
                                    : classes.unselectedLanguage
                            }
                        >
                            <span>{SupportedLanguages.en.toUpperCase()}</span>
                        </div>
                    </div>
                </a>
            </nav>
        </>
    );
};

export default LanguageSelector;
