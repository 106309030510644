import React, { useState } from 'react';
import useViewportWidth from '../../../hooks/useViewportWidth';
import { Divider } from '@mui/material';
import { IProductSheet } from '../../../interfaces/IProductSheet';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { SupportedLanguages } from '../../../common/i18n/SupportedLanguages';
import { WorkerMessageHandler } from 'pdfjs-dist/build/pdf.worker.min.js';
import { SkeletonSheetOverview } from './SkeletonSheetOverview';

pdfjs.GlobalWorkerOptions.workerSrc = WorkerMessageHandler;

// variant with CDN, also works in production
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface SheetPdfViewerProps {
    sheet: IProductSheet;
    language: string;
}

const HORIZONTAL_MARGIN_MOBILE = 100;
const HORIZONTAL_MARGIN_TABLET = 300;
const MOBILE = 1000;
const DESKTOP = 1780;
const MAX_WIDTH = 1600;

export function SheetPdfViewer(props: SheetPdfViewerProps) {
    const [numPages, setNumPages] = useState<number>();
    const mediaWidth = useViewportWidth();
    const [isLoadingfile, setIsLoadingfile] = useState<boolean>(true);

    const width =
        mediaWidth < MOBILE
            ? mediaWidth - HORIZONTAL_MARGIN_MOBILE
            : mediaWidth < DESKTOP
            ? mediaWidth - HORIZONTAL_MARGIN_TABLET
            : MAX_WIDTH;

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setIsLoadingfile(false);
    };

    const url = props.language === SupportedLanguages.de ? props?.sheet?.pdfLinkDe : props?.sheet?.pdfLinkEn;
    
    return (
        <>
            {isLoadingfile && <SkeletonSheetOverview file />}

            {url && width && (
                <Document file={{ url: url }} onLoadSuccess={onDocumentLoadSuccess} loading={null}>
                    {Array.from(new Array(numPages), (_, i) => (
                        <React.Fragment key={`page_${i + 1}`}>
                            <Page
                                pageNumber={i + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={width}
                            />
                            <Divider sx={{ height: '20px' }} />
                        </React.Fragment>
                    ))}
                </Document>
            )}
        </>
    );
}
