import { Theme } from '@mui/material';
import { WbCheckboxCustomEvent } from '@workbench/core';
import {
    WbAccordion,
    WbAccordionItem,
    WbCheckbox,
    WbGrid,
    WbGridCol,
    WbGridRow,
    WbHeading,
    WbText,
} from '@workbench/react';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../../common/i18n/useTranslation';

const useStyles = makeStyles()((theme: Theme) => ({
    gridContainer: {
        display: 'grid',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
    },
    accordionContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

export default function SettingsTab() {
    const { classes } = useStyles();
    const translate = useTranslation();
    const [isTracking, setIsTracking] = useState<boolean>(false);

    useEffect(() => {
        setIsTracking(Cookies.get('c_track_data') === 'true');
    }, []);

    const toggleTracking = (isTrackingValue: boolean) => {
        Cookies.remove('c_track_data');
        if (isTrackingValue) {
            Cookies.set('c_track_data', 'true', { expires: 365 });
        }
        setIsTracking(isTrackingValue);
    };

    return (
        <WbGrid className={classes.gridContainer} fullWidth>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="s">{translate('Cookies')}</WbHeading>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('SettingsTabHeader')}</WbHeading>
                    <WbText size="l">{translate('SettingsTabContent')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbAccordion prevent-mobile-flyout>
                        <WbAccordionItem>
                            <span slot="header">{translate('SettingsTabAccordionAnalysisTitle')}</span>
                            <span slot="mobile-header">{translate('Close')}</span>
                            <div slot="content" className={classes.accordionContent}>
                                <WbCheckbox
                                    name="newsletter"
                                    checked={isTracking}
                                    onWbchange={(
                                        e: WbCheckboxCustomEvent<{
                                            name: string;
                                            value: string;
                                            checked: boolean;
                                        }>
                                    ) => toggleTracking(e.detail.checked)}
                                >
                                    {translate('SettingsTabAccordionAnalysisCheckboxTitle')}
                                </WbCheckbox>
                                <WbText size="l">{translate('SettingsTabAccordionAnalysisContent')}</WbText>
                                <WbText size="l">{translate('SettingsTabAccordionAnalysisTrackDataCookie')}</WbText>
                            </div>
                        </WbAccordionItem>

                        <WbAccordionItem>
                            <span slot="header">{translate('SettingsTabAccordionEssentialTitle')}</span>
                            <span slot="mobile-header">{translate('Close')}</span>
                            <div slot="content" className={classes.accordionContent}>
                                <WbText size="l">{translate('SettingsTabAccordionEssentialInfo')}</WbText>
                                <WbCheckbox name="newsletter" disabled checked={true}>
                                    {translate('SettingsTabAccordionEssentialCheckboxTitle')}
                                </WbCheckbox>
                                <WbText size="l">{translate('SettingsTabAccordionEssentialContent')}</WbText>
                                <WbText size="l">{translate('SettingsTabAccordionEssentialDisclaimerCookie')}</WbText>
                            </div>
                        </WbAccordionItem>
                    </WbAccordion>
                </WbGridCol>
            </WbGridRow>
        </WbGrid>
    );
}
