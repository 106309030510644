import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { grey } from '@mercedes-benz/mui5-theme';

import { Theme} from '@mui/material/styles';
import { WbAspectRatio, WbGrid, WbGridCol, WbGridRow, WbSkeleton } from '@workbench/react';
import { useLoadingContext } from '../context/LoadingContext';

const useStyles = makeStyles()((theme: Theme) => {
    return {
        skeletonBackground: {
            backgroundColor: theme.palette.common.black,
            height: '100%',
        },

        skeleton: {
            backgroundColor: grey[30],
        },
        Textskeleton: {
            backgroundColor: grey[30],
            width: theme.spacing(50),
            height: theme.spacing(8),
        },
        Buttonskeleton: {
            backgroundColor: grey[30],
            width: theme.spacing(20),
            height: theme.spacing(8),
        },
        fileSkeleton: {
            backgroundColor: grey[30],
            height: theme.spacing(100),
        },
        image: {
            marginBottom: theme.spacing(5),

            maxHeight: theme.spacing(90),
        },
        button: {
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
    };
});

interface SkeletonSheetOverviewProps {
    file?: boolean;
}
export function SkeletonSheetOverview(props: SkeletonSheetOverviewProps) {
    const { loadData } = useLoadingContext();

    const { classes } = useStyles();

    const fileSkeleton = (
        <WbGridCol>
            <WbSkeleton className={classes.fileSkeleton} />
        </WbGridCol>
    );

    const userView = (
        <div className={classes.skeletonBackground}>
            <WbGrid>
                <WbAspectRatio ratio="3x2" className={classes.image}>
                    <WbSkeleton className={classes.skeleton} />
                </WbAspectRatio>

                <div>
                    <WbGrid>
                        <WbGridRow>
                            <WbGridCol mq1="12">
                                <>
                                    <WbGridRow>
                                        <WbGridCol mq1="8">
                                            <WbSkeleton className={classes.Textskeleton} />
                                        </WbGridCol>
                                        <WbGridCol mq1="4" className={classes.button}>
                                    {/*  The comment will be removed as soon as it is approved
                                     {      <WbSkeleton className={classes.Buttonskeleton} />} */}
                                        </WbGridCol>
                                    </WbGridRow>
                                    {fileSkeleton}
                                </>
                            </WbGridCol>
                        </WbGridRow>
                    </WbGrid>
                </div>
            </WbGrid>
        </div>
    );

    if (props.file) {
        return (
            <div className={classes.skeletonBackground}>
                <WbGridCol mq1="12">{fileSkeleton}</WbGridCol>
            </div>
        );
    }
    if (!loadData) {
        return <></>;
    }

    return userView;
}

export default React.memo(SkeletonSheetOverview);
