import React, { Suspense } from 'react';
import { OilFinder } from '../components/oilFinder/OilFinder';
import { MainSkeleton } from '../components/MainSkeleton';

function OilFinderRoute() {
    return (
        <Suspense fallback={<MainSkeleton />}>
            <OilFinder />
        </Suspense>
    );
}

export default React.memo(OilFinderRoute);
