import React, { createContext, ReactElement, ReactNode, useRef, useState } from 'react';
import { emptyProductSheetObject, IProductSheet } from '../../../interfaces/IProductSheet';
export interface CategoryMenuContext {
    categorySheetCollection: IProductSheet[];
    updateCategorySheets: (categorySheets: IProductSheet[]) => void;
    hasCategorySheetsItems: () => boolean;

    isFlyInContainerOpen: () => boolean;
    updateFlyInContainerOpen: (open: boolean) => void;

    getPreviouslyActiveNavItem: () => string;
    updatePreviouslyActiveNavItem: (navItemId: string) => void;
}

const initState: CategoryMenuContext = {
    categorySheetCollection: [],
    updateCategorySheets: () => {},
    hasCategorySheetsItems: () => false,
    isFlyInContainerOpen: () => false,
    updateFlyInContainerOpen: () => {},
    getPreviouslyActiveNavItem: () => '',
    updatePreviouslyActiveNavItem: () => {},
};

const eventContext = createContext<CategoryMenuContext>(initState);

export function CategoryMenuProvider(props: { children: ReactNode }): ReactElement {
    const [categorySheetCollection, setCategorySheetCollection] = useState<IProductSheet[]>([emptyProductSheetObject]);

    const flyInContainerOpen = useRef(false);
    const previouslyActiveNavItem = useRef('');

    const isFlyInContainerOpen = () => flyInContainerOpen.current;
    const updateFlyInContainerOpen = (open: boolean) => {
        flyInContainerOpen.current = open;
    };
    const getPreviouslyActiveNavItem = () => {
        return previouslyActiveNavItem.current;
    };
    const updatePreviouslyActiveNavItem = (navItemId: string) => {
        previouslyActiveNavItem.current = navItemId;
    };

    const updateCategorySheets = (categorySheets: IProductSheet[]) => {
        setCategorySheetCollection(categorySheets);
    };

    const hasCategorySheetsItems = () => {
        return categorySheetCollection.length > 0;
    };

    return (
        <eventContext.Provider
            value={{
                categorySheetCollection,
                updateCategorySheets,
                hasCategorySheetsItems,
                isFlyInContainerOpen,
                updateFlyInContainerOpen,
                getPreviouslyActiveNavItem,
                updatePreviouslyActiveNavItem,
            }}
        >
            {props.children}
        </eventContext.Provider>
    );
}

export function useCategoryMenuContext() {
    return React.useContext(eventContext);
}
