import Skeleton from '@mui/material/Skeleton';
import { Container, Grid, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme, useTheme } from '@mui/material/styles';
import React from 'react';

const useStyles = makeStyles()((theme: Theme) => ({
    mainContainer: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(2),
    },
}));

// EXAMPLE_CODE Mui Skeleton Component
function MainSkeletonComp() {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <>
            <Container className={classes.mainContainer}>
                <Skeleton variant="rectangular" width={theme.spacing(32)} height={theme.spacing(8)}></Skeleton>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                    <Grid item>
                        <Skeleton variant="text" width={theme.spacing(9)} height={theme.spacing(8)} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="text" width={theme.spacing(9)} height={theme.spacing(8)} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="text" width={theme.spacing(11)} height={theme.spacing(8)} />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Skeleton variant="rectangular" width={theme.spacing(24)} height={theme.spacing(5)}></Skeleton>
                </Box>
                <Box mt={4}>
                    <Skeleton variant="rectangular" height={theme.spacing(16)}></Skeleton>
                </Box>
                <Box mt={4}>
                    <Skeleton variant="rectangular" height={theme.spacing(6)}></Skeleton>
                </Box>
            </Container>
        </>
    );
}

export const MainSkeleton = React.memo(MainSkeletonComp);
