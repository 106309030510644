import { WbGrid, WbHeading, WbLink, WbList, WbListItem, WbText } from '@workbench/react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../common/i18n/useTranslation';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(4),
        color: theme.palette.common.white,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}));

export function NotFound() {
    const translate = useTranslation();
    const { classes } = useStyles();
    const navigate = useNavigate();

    return (
        <WbGrid className={classes.container}>
            <WbHeading size="l">{translate('NotFoundErrorOccurred')}</WbHeading>
            <WbText size="m">{translate('NotFoundErrorInfoText')}</WbText>
            <WbText size="l" strong>
                {translate('NotFoundErrorTryFollowing')}
            </WbText>
            <WbList theme="dark">
                <WbListItem>
                    {translate('NotFoundErrorTryFollowingHomepage')}
                    <WbLink variant="inline" theme="dark" onClick={() => navigate('/')}>
                        {translate('Homepage')}
                    </WbLink>
                </WbListItem>
                <WbListItem>{translate('NotFoundErrorTryFollowingSearch')}</WbListItem>
            </WbList>
        </WbGrid>
    );
}
