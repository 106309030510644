import * as React from 'react';
import { useState } from 'react';

export interface LoadingContext {
    loadData: boolean;
    startLoading: React.Dispatch<React.SetStateAction<void>>;
    stopLoading: React.Dispatch<React.SetStateAction<void>>;
}

const initState: LoadingContext = {
    loadData: {} as boolean,
    startLoading: () => {},
    stopLoading: () => {},
};

const loadingContext = React.createContext<LoadingContext>(initState);

export function LoadingProvider(props: { children: React.ReactNode }): React.ReactElement {
    const [loadData, setloadData] = useState<boolean>(false);
    let timer: NodeJS.Timeout | null = null;

    const startLoading = () => {
        timer = setTimeout(() => {
            setloadData(true);
        }, 200);
    };

    const stopLoading = () => {
        if (timer) {
            clearTimeout(timer);
        }

        setloadData(false);
    };
    return (
        <loadingContext.Provider
            value={{
                startLoading: startLoading,
                stopLoading: stopLoading,
                loadData: loadData,
            }}
        >
            {props.children}
        </loadingContext.Provider>
    );
}

export function useLoadingContext() {
    return React.useContext(loadingContext);
}
