import { Theme } from '@mui/material';
import { WbGrid, WbGridCol, WbGridRow, WbLink, WbText } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../common/i18n/useTranslation';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(2),
        padding: theme.spacing(6),
    },
    links: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: theme.spacing(3),
    },
    reservedRights: {
        display: 'flex',
        color: theme.palette.grey[500],
        justifyContent: 'center',
    },
}));

interface FooterProps {
    setInitialFooterModalTab: (tabName: string) => void;
    showFooterModal: () => void;
}

export function Footer(props: FooterProps) {
    const { classes } = useStyles();
    const translate = useTranslation();

    const showFooterModal = (tabName: string) => {
        props.setInitialFooterModalTab(tabName);
        props.showFooterModal();
    };

    return (
        <WbGrid className={classes.container}>
            <WbGridRow>
                <WbGridCol mq1={12} className={classes.links}>
                    <WbLink
                        variant="small"
                        theme="dark"
                        onClick={() => {
                            showFooterModal('provider');
                        }}
                    >
                        {translate('Provider')}
                    </WbLink>
                    <WbLink
                        variant="small"
                        theme="dark"
                        onClick={() => {
                            showFooterModal('legalNotice');
                        }}
                    >
                        {translate('LegalNotice')}
                    </WbLink>
                    <WbLink
                        variant="small"
                        theme="dark"
                        onClick={() => {
                            showFooterModal('settings');
                        }}
                    >
                        {translate('Settings')}
                    </WbLink>
                    <WbLink
                        variant="small"
                        theme="dark"
                        onClick={() => {
                            showFooterModal('dataPrivacy');
                        }}
                    >
                        {translate('PrivacyStatement')}
                    </WbLink>
                    <WbLink variant="small" theme="dark" href={'/contact'}>
                        {translate('Contact')}
                    </WbLink>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12} className={classes.reservedRights}>
                    <WbText size="s">
                        <FormattedMessage
                            id="ReservedRights"
                            defaultMessage={'ReservedRights'}
                            values={{ year: new Date().getFullYear() }}
                        />
                    </WbText>
                </WbGridCol>
            </WbGridRow>
        </WbGrid>
    );
}
