import { TextField, FilledTextFieldProps, StandardTextFieldProps, OutlinedTextFieldProps } from '@mui/material';
import { useEffect, useState, useRef } from 'react';

export interface FilledSearchProps extends FilledTextFieldProps {
    onChangeValue: (value: string) => void;
    label?: string;
}
export interface StandardSearchProps extends StandardTextFieldProps {
    onChangeValue: (value: string) => void;
    label?: string;
}
export interface OutlinedSearchProps extends OutlinedTextFieldProps {
    onChangeValue: (value: string) => void;
    label?: string;
}

type SearchProps = FilledSearchProps | StandardSearchProps | OutlinedSearchProps;

export function Search({onChangeValue, ...props}: SearchProps): JSX.Element {
    const [inputValue, setInputValue] = useState<string>('');
    const DELAY_MILLI_SECONDS = 1000; //  1 second

    const timer = useRef<NodeJS.Timeout>();

    const handleDelayedSearch = (query: string) => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            onChangeValue(query);
        }, DELAY_MILLI_SECONDS);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const query = event.target.value;
        setInputValue(query);

        handleDelayedSearch(query);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TextField
            id={Math.random().toString(36).substring(2, 9)}
            value={inputValue}
            onChange={handleInputChange}
            type="search"
            {...props}
        />
    );
}
