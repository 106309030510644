import React, { ReactElement, ReactNode, createContext } from 'react';

export interface ScrollLockContext {
    lockScroll: () => void;
    unlockScroll: () => void;
}

const initState: ScrollLockContext = {
    lockScroll: () => {},
    unlockScroll: () => {},
};

const eventContext = createContext<ScrollLockContext>(initState);

export function ScrollLockProvider(props: { children: ReactNode }): ReactElement {
    const lockScroll = () => {
        document.body.dataset.scrollLock = 'true';
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar-compensation)';
    };

    const unlockScroll = () => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
        delete document.body.dataset.scrollLock;
    };

    return (
        <eventContext.Provider
            value={{
                lockScroll,
                unlockScroll,
            }}
        >
            {props.children}
        </eventContext.Provider>
    );
}

export function useScrollLockContext() {
    return React.useContext(eventContext);
}
