import React, { ReactNode, useState, useEffect } from 'react';
import Header from './general/header/Header';
import { Footer } from './general/Footer';
import FooterModal from './general/footerModal/FooterModal';
import CookieBar from './general/cookieBar/CookieBar';
import Cookies from 'js-cookie';
import * as ackeeTracker from 'ackee-tracker';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useScrollLockContext } from '../utility/ScrollLockContext';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        minHeight: '100vh',
        '& main': {
            flexGrow: 1,
        },
    },
}));
interface FrameProps {
    children: ReactNode;
}

export function Frame(props: FrameProps) {
    const { classes } = useStyles();
    const isTracking = Cookies.get('c_track_data') === 'true';
    const { lockScroll, unlockScroll } = useScrollLockContext();

    useEffect(() => {
        isTracking &&
            ackeeTracker
                .create('https://ackee-bevo.app.corpintra.net', {
                    detailed: true,
                    ignoreLocalhost: true,
                    ignoreOwnVisits: true,
                })
                .record('37ef598b-d279-4888-b79a-9f798766e338');
    }, [isTracking]);

    const [footerModalVisible, setFooterModalVisible] = useState<boolean>(false);
    const [footerModalTabName, setFooterModalTabName] = useState<string>('settings');
    const [cookieBarVisible, setCookieBarVisible] = useState<boolean>(Cookies.get('c_disclaimer') === undefined);

    const showFooterModal = () => {
        setFooterModalVisible(true);
        lockScroll();
    };
    const hideFooterModal = () => {
        setFooterModalVisible(false);
        unlockScroll();
    };
    const hideCookieBar = () => {
        setCookieBarVisible(false);
    };

    const setInitialFooterModalTabName = (tabName: string) => {
        setFooterModalTabName(tabName);
    };

    return (
        <div className={classes.container}>
            <Header />
            <main>{props.children}</main>
            <Footer setInitialFooterModalTab={setInitialFooterModalTabName} showFooterModal={showFooterModal} />
            {footerModalVisible && (
                <FooterModal hideFooterModal={hideFooterModal} footerModalTabName={footerModalTabName} />
            )}
            {cookieBarVisible && <CookieBar showFooterModal={showFooterModal} hideCookieBar={hideCookieBar} />}
        </div>
    );
}

export default React.memo(Frame);
