import React, { Suspense } from 'react';
import { MainPage } from '../components/mainPage/MainPage';
import { MainSkeleton } from '../components/MainSkeleton';

function MainRoute() {
    return (
        <Suspense fallback={<MainSkeleton />}>
            <MainPage />
        </Suspense>
    );
}

export default React.memo(MainRoute);
