import VehicleSkeletonHeader from './subcomponent/carSkeletonHeader/VehicleSkeletonHeader';
import TeaserContainer from './subcomponent/teaser/TeaserContainer';
import CategorySlider from './subcomponent/categorySlider/CategorySlider';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(6),
    },
}));

export function MainPage() {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <VehicleSkeletonHeader />
            <CategorySlider />
            <TeaserContainer />
        </div>
    );
}
