import { useEffect } from 'react';
import CategoryInterested from './categoryList/CategoryInterested';
import { CategoryTable } from './categoryTable/CategoryTable';
import { HeaderBanner } from './headerBanner/HeaderBanner';
import Prolog from './prolog/Prolog';
import { makeStyles } from 'tss-react/mui';
import { CategoryDescription } from '../../common/Constants';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(5),
    },
}));

interface CategoryOverviewProps {
    description: CategoryDescription;
    image: any;
}

export function CategoryOverview(props: CategoryOverviewProps) {
    const { classes } = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.description, props.image]);

    return (
        <div className={classes.container}>
            <HeaderBanner image={props.image} description={props.description} />
            <Prolog description={props.description} />
            <CategoryTable description={props.description} />
            <CategoryInterested description={props.description} />
        </div>
    );
}
