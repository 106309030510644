import React, { Suspense } from 'react';
import { QrOverview } from '../components/qr/QrOverview';
import { MainSkeleton } from '../components/MainSkeleton';

function QrOverviewRoute() {
    return (
        <Suspense fallback={<MainSkeleton />}>
            <QrOverview />
        </Suspense>
    );
}

export default React.memo(QrOverviewRoute);
