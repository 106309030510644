import { WbInputCustomEvent } from '@workbench/core';
import { useReducer } from 'react';

const initialInputState = {
    value: '',
    isTouched: false,
};

const inputStateReducer = (state: any, action: any) => {
    if (action.type === 'INPUT_CHANGED') {
        return {
            value: action.value,
            isTouched: state.isTouched,
        };
    }
    if (action.type === 'INPUT_BLUR') {
        return {
            value: state.value,
            isTouched: true,
        };
    }
    if (action.type === 'INPUT_RESET') {
        return initialInputState;
    }
    return initialInputState;
};

const useInput = (validateValue: (value: string) => boolean) => {
    const [inputState, dispatch] = useReducer(inputStateReducer, initialInputState);

    const inputIsValid = validateValue(inputState.value);
    const hasError = !inputIsValid && inputState.isTouched;

    const inputChangeHandler = (e: WbInputCustomEvent<{ name: string; value: string }>) => {
        dispatch({ type: 'INPUT_CHANGED', value: e.detail.value });
    };

    const inputBlurHandler = () => {
        dispatch({ type: 'INPUT_BLUR' });
    };

    const reset = () => {
        dispatch({ type: 'INPUT_RESET' });
    };

    return {
        input: inputState.value,
        isValid: inputIsValid,
        hasError,
        inputChangeHandler,
        inputBlurHandler,
        reset,
    };
};

export default useInput;
