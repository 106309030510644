import { WbAspectRatio, WbButton, WbGridCol, WbGridRow, WbHeading, WbIcon, WbText } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { useTranslation } from '../../../../common/i18n/useTranslation';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(1),
    },
}));

interface TeaserProps {
    title: string;
    text: string;
    url: string;
    image: string;
}

export default function Teaser(props: TeaserProps) {
    const { classes } = useStyles();
    const { title, text, url, image } = props;
    const translate = useTranslation();

    return (
        <WbGridRow>
            <WbGridCol mq1={12} mq2={6} mq3={6} mq4={6} mq5={6} mq6={6}>
                <WbAspectRatio ratio="3x2">
                    <img src={image} alt="alt text" />
                </WbAspectRatio>
            </WbGridCol>
            <WbGridCol className={classes.container} mq1={12} mq2={6} mq3={6} mq4={6} mq5={6} mq6={6}>
                <WbHeading tag="h2" size="m">
                    {title}
                </WbHeading>
                <WbText tag="p" size="s">
                    {text}
                </WbText>
                <WbButton variant="primary" size="s" href={url} target="_blank" style={{ width: '100%' }}>
                    <WbIcon slot="icon" name="chevron-right" />
                    {translate('LearnMore')}
                </WbButton>
            </WbGridCol>
        </WbGridRow>
    );
}
