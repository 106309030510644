import { WbAspectRatio, WbGridCol, WbHeading } from '@workbench/react';
import { Link } from 'react-router-dom';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
    title: {
        marginTop: theme.spacing(1),
    },
}));

interface CategoryListTeaserProps {
    url: string;
    image: string;
    description: string;
    title: string;
}

export default function CategoryListTeaser(props: CategoryListTeaserProps) {
    const { classes } = useStyles();

    return (
        <WbGridCol mq1={12} mq2={4} mq3={4} mq4={4} mq5={4} mq6={4}>
            <Link to={props.url} className={classes.container}>
                <WbAspectRatio ratio="2x1">
                    <img src={props.image} alt={props.description} />
                </WbAspectRatio>
                <WbHeading size="m" className={classes.title}>
                    {props.title}
                </WbHeading>
            </Link>
        </WbGridCol>
    );
}
