import { Theme } from '@mui/material';
import { WbGrid, WbGridCol, WbGridRow, WbHeading, WbLink, WbText } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles()((theme: Theme) => ({
    gridContainer: {
        display: 'grid',
        gap: theme.spacing(3),
        padding: theme.spacing(1),
    },
    preLine: {
        whiteSpace: 'pre-line',
    },
}));

export default function PrivacyStatementTab() {
    const { classes } = useStyles();
    const translate = useTranslation();

    return (
        <WbGrid className={classes.gridContainer} fullWidth>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="s">{translate('PrivacyStatementTabTitle')}</WbHeading>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('PrivacyStatementTabGdprControllerTitle')}</WbHeading>
                    <WbText size="l" className={classes.preLine}>
                        <FormattedMessage
                            id="PrivacyStatementTabGdprControllerAddress"
                            defaultMessage={'PrivacyStatementTabGdprControllerAddress'}
                            values={{ br: <br /> }}
                        />
                        <WbLink variant="inline" href="mailto:dialog.mb@mercedes-benz.com">
                            dialog.mb@mercedes-benz.com
                        </WbLink>
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">{translate('PrivacyStatementTabDataProtectionTitle')}</WbHeading>
                    <WbText size="l" className={classes.preLine}>
                        <FormattedMessage
                            id="PrivacyStatementTabDataProtectionAddress"
                            defaultMessage={'PrivacyStatementTabDataProtectionAddress'}
                            values={{ br: <br /> }}
                        />
                        <WbLink variant="inline" href="mailto:data.protection@mercedes-benz.com">
                            data.protection@mercedes-benz.com
                        </WbLink>
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection1Title')}
                    </WbHeading>
                    <WbText size="l">
                        <FormattedMessage
                            id="PrivacyStatementTabDataProtectionContentSection1Content1"
                            defaultMessage={'PrivacyStatementTabDataProtectionContentSection1Content1'}
                            values={{ br: <br /> }}
                        />
                        <WbLink
                            variant="inline"
                            href="https://group.mercedes-benz.com/dokumente/konzern/sonstiges/daimler-datenschutzrichtlinie-de.pdf"
                        >
                            {translate('PrivacyStatementTabDataProtectionContentSection1LinkTitle')}
                        </WbLink>
                    </WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection1Content2')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection2Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection2Content1')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection2Content2')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection2Content3')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection3Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection3Content1')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection3Content2')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection3Content3')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection3Content4')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection4Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection4Content1')}</WbText>
                    <br />
                    <WbText size="l">
                        <FormattedMessage
                            id="PrivacyStatementTabDataProtectionContentSection4Content2"
                            defaultMessage="PrivacyStatementTabDataProtectionContentSection4Content2"
                            values={{ br: <br /> }}
                        />
                    </WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection4Content3')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection4Content4')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection5Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection5Content1')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection5Content2')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection5Content3')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection5Content4')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection5Content5')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection5Content6')}</WbText>
                    <br />
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection6Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection6Content1')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection7Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection7Content1')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection7Content2')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection7Content3')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection7Content4')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection8Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection8Content1')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection9Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection9Content1')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection9Content2')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection9Content3')}</WbText>
                    <br />
                    <WbText size="l">
                        {translate('PrivacyStatementTabDataProtectionContentSection9Content4')}
                        <WbLink href="mailto:kundenrechte_mb@mercedes-benz.com" variant="inline">
                            {'kundenrechte_mb@mercedes-benz.com'}
                        </WbLink>
                    </WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection9Content5')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection10Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection10Content1')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection11Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection11Content1')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="xs">
                        {translate('PrivacyStatementTabDataProtectionContentSection12Title')}
                    </WbHeading>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection12Content1')}</WbText>
                    <br />
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSection12Content2')}</WbText>
                </WbGridCol>
            </WbGridRow>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbText size="l">{translate('PrivacyStatementTabDataProtectionContentSectionsLastUpdated')}</WbText>
                </WbGridCol>
            </WbGridRow>
        </WbGrid>
    );
}
