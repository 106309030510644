import axios from 'axios';
import { BASE_URL } from '../common/Constants';
import { getCategoryAsGermanString } from './Helper';
import { IContact } from '../interfaces/IContact';

export const getSheetsByCategory = (category: string) => {
    const categoryUrl = getCategoryAsGermanString(category);
    return axios.get(`${BASE_URL}category/${categoryUrl}`);
};

export const getSheetNumbersByCategory = (category: string) => {
    const categoryUrl = getCategoryAsGermanString(category);
    return axios.get(`${BASE_URL}categorySheetNumbers/${categoryUrl}`);
};

export const getSheetByNumber = (sheetNumber: string) => {
    return axios.get(`${BASE_URL}sheet/${sheetNumber}`);
};

export const getSearchResults = (text: string) => {
    return axios.get(`${BASE_URL}search/${text}`);
};

export const getProductsById = (productId: string) => {
    return axios.get(`${BASE_URL}product/${productId}`);
};

export async function sendContact(contactData: IContact) {
    const data = await axios.post(`${BASE_URL}contact`, contactData);
    return data;
}
